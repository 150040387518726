#quote {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .order-link {
        background-color: $brand-color;
        text-align: center;
        padding-top: 25px;
        padding-bottom: 25px;
        font-weight: bold;
        color: white;

        a {
            color: white;
            font-style: italic;
        }
    }

    // .order-button-link {
    //     margin: 0 auto; 
    //     margin-bottom: 25px;
    //     background-color: $mc-blue;
    //     text-align: center;
    //     display: inline-block;
    //     text-align: center;
    //     box-shadow: $small-shadow;
    //     padding: 15px 15px 10px 15px;
    //     font-weight: bold;
    //     color: white;
    //     text-transform: uppercase;
    //     text-decoration: none;
    //     border-radius: 5px;
    // }

}
.quote-form {
    border: none;
    background-color: #f1f1f1;
    padding: 25px;

    p {
        max-width: 800px;
        margin: 0px auto;
        margin-bottom: 25px; 
        font-size: 1.6rem;
    }
}

.quote-form-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .form-input {
        width: 200px;


    @include respond-below(xs) {
        width: 100%;
    }
    }

}

.quote-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    width: 100%;

    @include respond-below(sm) {
        display: flex;
        flex-wrap: wrap;
    }
}

.quote-detail {
    padding-bottom: 25px;
    max-width: 1000px;
    margin: 0 auto;

    @include respond-below(sm) {
        p {
            font-size: 1.4rem;
        }
    }
}

.quote-detail-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f1f1f1;
    overflow:scroll; 
}

.quote-detail-info {
    min-width: 500px;
    background-color: #f1f1f1;
    font-size: 1.4rem;
    width: 100%;
    padding: 25px;
    margin-bottom: 10px;
    li {
        margin-bottom: 5px;
    }
}


.pickup-delivery-details {
    margin-top: 50px;
    min-width: 500px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .row {
        svg {
            margin-left: 10px;
        }
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin-bottom: 25px;
        grid-gap: 10px;

        @include respond-below(sm) {
            width: 100%;
        }
    }

    .full-row {
        width: 100%;
        grid-template-columns: 1fr;
    }

    .action-row {
        width: 100%;
        text-align: center;

        button {
            margin: 15px;
            min-width: 200px;

            a {
                color: white;
                text-decoration: none;
            }
        }
    }

    .row-label {
        font-weight: bold;
        font-size: 1.4rem;
    }

    padding: 25px;
}

.quote-detail-actions {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;


    button {
        background-color: $mc-blue;
        margin: 25px;
        width: 150px;
    }

    a {
        color: white;
        text-decoration: none;
    }
}



.quote-detail-table {

    grid-template-columns: repeat(8, minmax(100px, 1fr));
}

.pricing-detail {
    border: 1px solid $dark-text;
}

.pricing-detail-table {

    grid-template-columns: repeat(5, minmax(100px, 1fr));
}

.pickup-delivery-details {
    color: $dark-text;
}

.quote-detail-vehicles {
    width: 100%;
    margin-bottom: 10px;
}


#email-row {
    display: flex;
    justify-content: center;
}